import React, { Fragment } from "react";

const serviceFaqs = [
  {
    id: "flush-collapseOne",
    question: "Who owns the virtual influencer?",
    answer:
      "Our client would own the virtual influencer. We would be a technical partner entrusted with creating content as per the direction of your marketing/creative team.",
  },
  {
    id: "flush-collapseTwo",
    question: "How would you create the influencer?",
    answer:
      "We have a proprietary workflow where we create the identity of the virtual influencer based on your suggestions - any ethnicity, size, skin-tone, body-shape, etc.",
  },
  {
    id: "flush-collapseThree",
    question: "Can Nexusware virtual influencer resemble a real person?",
    answer:
      "From a technological standpoint, that probability is the same probability as being born with a doppleganger. However, every virtual influencer we create is passed through a third party facial recognition tool that scans the internet and checks for similar faces (www.pimeyes.com). If there is a match, we create another one until we are absolutely certain it doesn’t resemble any face on the internet. ‘seed’ model is exclusively reserved for your brand at a nominal fee.",
  },
  {
    id: "flush-collapseFour",
    question:
      "What happens if Nexusware send across an image that you don't like?",
    answer:
      "No problem. We’ll re-do it. As long as you give us feedback on why you don’t like it, we’re happy to change it out any number of times.",
  },
];

const generalFaqs = [
  {
    id: "flush-collapseOnes",
    question: "What is the pricing structure?",
    answer:
      "We will create a monthly retainer model depending on the volume of content required. Over a period of 6 months, we believe this will be a higher ROI proposition than tying up with real influencers.",
  },
  {
    id: "flush-collapseTwos",
    question: "Has this been done by anybody before?",
    answer:
      "Internationally this is a very popular concept in countries like the US, Brazil, Japan, China, and Korea. In India as well, there are a couple of famous virtual influencers including Myntra’s Maya_Unlimited which was created in partnership with us on a similar model.",
  },
  {
    id: "flush-collapseThrees",
    question: "Can we generat videos as well or is it limited to static?",
    answer:
      "While videos are possible, the state of generative AI is such that it results in some hallucinations at this stage. What that means is that the face or clothes may change slightly which inhibits actual video production significantly. That being said, the technology is advancing rapidly and we expect that we will be able to make the transition to video in a few months, but not as yet.",
  },
  {
    id: "flush-collapseFours",
    question: "What is special about Nexusware technology?",
    answer:
      "Keeping the model consistent in different lighting environments is one of our core USPs. Secondly, the ability to dress her in any apparel from just a catalogue image consistently is another key differentiator.",
  },
];

const Virtualinfluencercards = () => {
  return (
    <Fragment>
      <div className="tabs_section accordion_section" id="about">
        <div className="container">
          <div className="d-flex justify-content-start align-items-center gap-3">
            <h1 className="mb-4 card_title faq_heading">FAQs</h1>
            <p className="faq_line"></p>
          </div>
          <div className="row flex-wrap-md-reverse">
            <div className="col-md-12 mt-2 response-device-padding">
              <div className="faq_info_tab">
                <ul
                  className="nav nav-pills mb-4"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item me-4" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-one-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-one"
                      type="button"
                      role="tab"
                      aria-controls="pills-one"
                      aria-selected="true"
                    >
                      Service FAQs
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-two-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-two"
                      type="button"
                      role="tab"
                      aria-controls="pills-two"
                      aria-selected="false"
                    >
                      General FAQs
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-one"
                    role="tabpanel"
                    aria-labelledby="pills-one-tab"
                    tabIndex="0"
                  >
                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      {serviceFaqs.map((faq, index) => (
                        <div className="accordion-item" key={index}>
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#${faq.id}`}
                              aria-expanded="false"
                              aria-controls={faq.id}
                            >
                              {faq.question}
                            </button>
                          </h2>
                          <div
                            id={faq.id}
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">{faq.answer}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-two"
                    role="tabpanel"
                    aria-labelledby="pills-two-tab"
                    tabIndex="1"
                  >
                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExamples"
                    >
                      {generalFaqs.map((faq, index) => (
                        <div className="accordion-item" key={index}>
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#${faq.id}`}
                              aria-expanded="false"
                              aria-controls={faq.id}
                            >
                              {faq.question}
                            </button>
                          </h2>
                          <div
                            id={faq.id}
                            className="accordion-collapse collapse"
                            aria-labelledby={`flush-heading${index}`}
                            data-bs-parent="#accordionFlushExamples"
                          >
                            <div className="accordion-body">{faq.answer}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Virtualinfluencercards;
