import React, { Fragment } from "react";

const faqs = [
  {
    id: "pills-one",
    tab: "service faqs",
    questions: [
      {
        id: "flush-collapseOne",
        question:
          "What are the guidelines for sending Nexusware a catalog image?",
        answer:
          "Most conventionally shot catalog images should be absolutely fine. All we need is the good diffused lighting that portrays your apparel with the right colour. We’ll do the rest.",
      },
      {
        id: "flush-collapseTwo",
        question:
          "What happens if Nexusware sends across an image that you don’t like?",
        answer:
          "No problem. We’ll re-do it. As long as you give us feedback on why you don’t like it, we’re happy to change it out any number of times.",
      },
      {
        id: "flush-collapseThree",
        question: "What is the pricing structure?",
        answer:
          "We process images on a cost-per-image basis. The price would be dependent on the volume of images and the Turn Around Time (TAT) required. Secondly, if we are able to roll this out with you on a recurring monthly model with a minimum quantity (versus a pay-as-you-go model) we would be able to offer you a lower rate. The idea is that we can then deploy a team specifically for your brand. We recommend a pay-as-you-go model first so you are comfortable with us first.",
      },
      {
        id: "flush-collapseFour",
        question:
          "Are there any restrictions on the type of apparel Nexusware can process?",
        answer:
          "With translucent or netted fabrics, there could be an issue when you change the model ethnicity or skin-colour. Except for that, we should be all clear.",
      },
      {
        id: "flush-collapseFive",
        question: "Can you shoot conventional catalog images?",
        answer:
          "Yes we can. We’re based out of Bangalore, India so you would have to send us the apparel. We can shoot conventional catalog images and send you both - the 5/6 images you get from a conventional catalog shoot, as well as one image swapped out into a marketing image. Alternatively, we can collaborate with a local studio that you work with too to make this a seamless service for you too.",
      },
      {
        id: "flush-collapseSix",
        question: "Can Nexusware change the pose of the input model?",
        answer:
          "We prefer not to do this as each fabric is different and the drape and fall could change.",
      },
    ],
  },
  {
    id: "pills-two",
    tab: "general faqs",
    questions: [
      {
        id: "flush-collapseOnes",
        question: "Do you offer a free pilot?",
        answer:
          "Absolutely. We’re happy to process a few images. We’re confident you’ll love us once you’ve tried us out!",
      },
      {
        id: "flush-collapseTwos",
        question:
          "Can Nexusware's synthetic human's face resemble a real person?",
        answer:
          "From a technological standpoint, that probability is the same probability as being born with a doppleganger. However, every face we create is passed through a third party facial recognition tool that scans the internet and checks for similar faces (www.pimeyes.com). If there is a match, we simply regenerate the image till we get a totally unique face. This way your images are totally safe to use.",
      },
      {
        id: "flush-collapseThrees",
        question:
          "Can Nexusware make sure that my synthetic model is used exclusively for my brand?",
        answer:
          "If that is important to you, absolutely! Today Nexusware does generate a ton of synthetic models so the same model being noticed on multiple brands/platforms is a rarity. However, we can ensure that ‘seed’ model is exclusively reserved for your brand at a nominal fee.",
      },
      {
        id: "flush-collapseFours",
        question: "Are Nexusware images live anywhere at the moment?",
        answer:
          "Yes they are. However it’s at the discretion of the brand to let people know that they are AI model images. So we can’t share links here unfortunately but do get in touch and we’ll be able to solve any apprehensions.",
      },
      {
        id: "flush-collapseFives",
        question:
          "How is this different from other AI images generators or an image editing software's 'Gen-AI Fill' function?",
        answer:
          "All diffusion based image generators typically suffer from some form of hallucinations. They end up slightly changing your garment’s pattern/silhouette, thereby making the process not conducive for fashion brands where the garment needs to be replicated exactly as is. With regard to Gen-AI fill tools, the lighting doesn’t end up coming out right; it ends up looking like a cookie-cutter pasted image. With Nexusware, the lighting of the source image is woven into the generation process itself in a manner that makes the images much more real. When it comes to marketing/editorial images, magic lies in that last bit of finesse. That’s where Nexusware stands out.",
      },
    ],
  },
];

const Faq = () => {
  return (
    <Fragment>
      <div className="tabs_section accordion_section" id="about">
        <div className="container">
          <div className="d-flex justify-content-start align-items-center gap-3">
            <h1 className="mb-4 card_title faq_heading">FAQs</h1>
            <p className="faq_line"></p>
          </div>
          <div className="row flex-wrap-md-reverse">
            <div className="col-md-12 mt-2 response-device-padding">
              <div className="faq_info_tab">
                <ul
                  className="nav nav-pills mb-4"
                  id="pills-tab"
                  role="tablist"
                >
                  {faqs.map((faq, index) => (
                    <li
                      className="nav-item me-4"
                      role="presentation"
                      key={index}
                    >
                      <button
                        className={`nav-link ${index === 0 ? "active" : ""}`}
                        id={`${faq.id}-tab`}
                        data-bs-toggle="pill"
                        data-bs-target={`#${faq.id}`}
                        type="button"
                        role="tab"
                        aria-controls={faq.id}
                        aria-selected={index === 0}
                      >
                        {faq.tab}
                      </button>
                    </li>
                  ))}
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  {faqs.map((faq, index) => (
                    <div
                      className={`tab-pane fade ${
                        index === 0 ? "show active" : ""
                      }`}
                      id={faq.id}
                      role="tabpanel"
                      aria-labelledby={`${faq.id}-tab`}
                      key={index}
                      tabIndex={index}
                    >
                      <div
                        className="accordion accordion-flush"
                        id={`accordionFlushExample${index}`}
                      >
                        {faq.questions.map((q, qIndex) => (
                          <div className="accordion-item" key={qIndex}>
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#${q.id}`}
                                aria-expanded="false"
                                aria-controls={q.id}
                              >
                                {q.question}
                              </button>
                            </h2>
                            <div
                              id={q.id}
                              className="accordion-collapse collapse"
                              data-bs-parent={`#accordionFlushExample${index}`}
                            >
                              <div className="accordion-body">{q.answer}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Faq;
