import React, { Fragment } from "react";
import img1 from "../../assets/images/brand.png";
import img2 from "../../assets/images/catalogue.png";
import img3 from "../../assets/images/collection.png";

const cardsData = [
  {
    img: img1,
    heading: "Fast Fashion Retailers & Manufacturers",
    paragraph: `We enable low-cost value brands to upgrade their mannequin or flat-lay images to a human images with international models. This will lead to an increase in your conversion - specifically the ‘List Page to Product Page’ and the ‘Product Page’ to ‘Cart Page’.`,
  },
  {
    img: img2,
    heading: "Cataloging Studios",
    paragraph: `We enable cataloging studios to add another service offering to their product portfolio. Specifically, we partner with them to deliver a low cost alternative to their value-driven client base while also making a good margin in the process.`,
  },
  {
    img: img3,
    heading: "E-Commerce Marketplaces",
    paragraph: `We enable platforms to make the customer’s shopping experience more enjoyable through better visuals. Our EasyShoot solution is a cost- effectively tool to lend the platform a much more premium look - and - feel as against plain vanilla studio shot images.`,
  },
];

const Nomodalcards = () => {
  return (
    <Fragment>
      <div className="vitrual_section" style={{ position: "relative" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
          className="svg"
        >
          <path
            className="elementor-shape-fill"
            d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                  c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                  c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
          ></path>
        </svg>
        <div className="container">
          <div
            className="row virtual-influencer_section"
            style={{ position: "relative" }}
          >
            <h1 className="card_title">NO-MODEL is loved by...</h1>
            <div className="row p-2 row-cols-1 row-cols-md-3 g-4">
              {cardsData.map((card, index) => (
                <div
                  key={index}
                  className={`col-md-12 ${
                    index === 2 ? "col-lg-12" : "col-lg-6"
                  } col-xl-4 col-sm-12`}
                >
                  <div className="card easyshoot_card h-100">
                    <div className="card-body">
                      <div className="media d-flex justify-content-start align-items-center mb-3">
                        <img
                          className="mr-3 me-3 card_img"
                          src={card.img}
                          alt="Generic placeholder image"
                        />
                        <div className="media-body">
                          <h5 className="mt-0 card_heading">{card.heading}</h5>
                        </div>
                      </div>
                      <p className="card_paragraph">{card.paragraph}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Nomodalcards;
