import React, { Fragment } from "react";
import OwlCarousel from "react-owl-carousel";

const Nomodel = () => {
  const options = {
    margin: 16,
    nav: false,
    loop: true,
    autoplay: true,
    animateOut: "fadeOut",
    dots: false,
    touchDrag: false,
    autoplayTimeout: 1300,
    autoplaySpeed: 1300,
    mouseDrag: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  return (
    <Fragment>
      <div className="nomodel_section">
        <div className="container">
          <div className="row">
            <div
              className="col-md-12 col-lg-7 col-sm-12 m-auto animation_div"
              style={{ paddingLeft: "5%" }}
            >
              <h1 className="banner_section_heading red_text">NOMODEL</h1>
              <h2 className="mb-4 banner_sub_heading">MANNEQUIN AI MODEL</h2>
              <p className="banner_section_paragraph mt-4">
                Substitute the mannequin for an synthetic human of any
                ethnicity.
              </p>
            </div>
            <div
              className="col-md-12 col-lg-5 col-sm-12 rounded"
              style={{ borderRadius: "0px 50px 0px 50px" }}
            >
              <OwlCarousel classNameName="owl-theme" {...options}>
                <div classNameName="item">
                  <main className="bounce_img">
                    <div className="carousel">
                      <figure></figure>
                    </div>
                  </main>
                </div>
                <div className="item">
                  <main className="bounce_img">
                    <div className="carousel">
                      <figure className="figure2"></figure>
                    </div>
                  </main>
                </div>
                <div className="item">
                  <main className="bounce_img">
                    <div className="carousel">
                      <figure className="figure3"></figure>
                    </div>
                  </main>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Nomodel;
