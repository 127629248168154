import React from "react";
import Models from "./homepage/Models";
import Scrollbanner from "./homepage/Scrollbanner";
import Nomodel from "./homepage/Nomodel";
import Virtualinfluncers from "./homepage/Virtualinfluncers";

const Banner = () => {
  return (
    <React.Fragment>
      <Models />
      <Scrollbanner />
      <Nomodel />
      {/* <Virtualinfluncers /> */}
    </React.Fragment>
  );
};

export default Banner;
