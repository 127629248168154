import React, { Fragment, useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Login";
import Forgot from "./Forgot";
import Header from "./Header";
import Footer from "./Footer";
import Dashboard from "./Dashboard";
import Banner from "./Banner";
import Pagenotfound from "./Pagenotfound";
import Contact from "./contact/Contact";
import Custombanner from "./homepage/Custombanner";
import Faceswap from "./faceswap/Faceswap";
import About from "./about/About";
import Apperelswap from "./apperelswap/Apperelswap";
import Modelswap from "./modalswap/Modelswap";
import Nomodal from "./nomodal/Nomodal";
import Virtualinfluencer from "./Virtualinfluencer/Virtualinfluencer";

const Routers = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Pagenotfound />} />
          <Route path="/" element={<Dashboard />}>
            <Route index element={<Banner />} />
            <Route path="/faceswap" index element={<Faceswap />} />
            <Route path="/apparel-swap" index element={<Apperelswap />} />
            <Route path="/modal-swap" index element={<Modelswap />} />
            <Route path="/nomodel" index element={<Nomodal />} />
            <Route
              path="/virtual-influencer"
              index
              element={<Virtualinfluencer />}
            />
            <Route path="/about" index element={<About />} />
            <Route path="/contact" index element={<Contact />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
};

export default Routers;
