import React, { Fragment, useState } from "react";

const tabData = [
  {
    id: 1,
    title: "COSTS",
    description: "40% Cheaper than conventional shoots.",
    imgSrc:
      "https://img.freepik.com/free-vector/startup-managers-presenting-analyzing-sales-growth-chart-group-workers-with-heap-cash-rocket-bar-diagrams-with-arrow-heap-money_74855-14166.jpg?t=st=1717776048~exp=1717779648~hmac=3280c4b8fcee4124ecb15031b09b69aff8bcd48deb143d7b2e81d8d6211f2727&w=996",
  },
  {
    id: 2,
    title: "NO MOQ",
    description: "ZERO Minimum Quantity for the Shoots",
    imgSrc:
      "https://img.freepik.com/free-vector/hand-drawn-flat-design-benchmark-illustration_23-2149331621.jpg?t=st=1717781715~exp=1717785315~hmac=9252c1d73b73402c12702decb4e8f29a4ad3d9caf985ffcc0874676f7ed54b1f&w=740",
  },
  {
    id: 3,
    title: "COVERSION",
    description: "5%+ Higher conversion with international models",
    imgSrc:
      "https://img.freepik.com/free-vector/agile-method-concept-illustration_114360-9826.jpg?t=st=1717778676~exp=1717782276~hmac=93d38210ceffa6f826649ebc7e4ad4e0caae41571052ff2488ee2f800ac65cee&w=996",
  },
  {
    id: 4,
    title: "USAGE RIGHTS",
    description: "Zero Restriction on usage",
    imgSrc:
      "https://img.freepik.com/free-vector/intellectual-property-concept-illustrated_52683-48609.jpg?t=st=1717776290~exp=1717779890~hmac=2d6dd2e9798faf8f5cc2f2917ccf432889134ee2cb4971cd54d0d2d61a64c35c&w=900",
  },
  {
    id: 5,
    title: "VARIETY",
    description: "∞ ethnicity",
    imgSrc:
      "https://img.freepik.com/free-vector/seminar-concept-illustration_114360-7480.jpg?t=st=1717781506~exp=1717785106~hmac=e2c8a6db3f25c65770f6637e1dcf6fda745e619e085f9753e33a89d6d472611f&w=996",
  },
];

const Modaltabs = () => {
  const [activeTab, setActiveTab] = useState(1);

  // Function to handle tab change
  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  return (
    <Fragment>
      <section className="tabs_section">
        <div className="container">
          <div className="row g-4">
            <h6 className="international_heading_main_heading">
              Our Value Proposition
            </h6>

            <div className="tabs">
              <div className="row g-4">
                <div className="col-lg-4 col-md-12">
                  <ul id="tabs-nav">
                    {tabData.map((tab) => (
                      <li
                        key={tab.id}
                        className={activeTab === tab.id ? "active" : ""}
                        onMouseEnter={() => handleTabChange(tab.id)}
                      >
                        <a href="javascript:void(0);">
                          <h6 className="text-start international_heading_sub_heading">
                            {tab.title}
                          </h6>
                          <h5
                            className="customer_descriptions"
                            style={{
                              display: activeTab === tab.id ? "block" : "none",
                            }}
                          >
                            {tab.id === 5 ? (
                              <span>
                                &#8734;
                                <span> {tab.description.split(" ")[1]}</span>
                              </span>
                            ) : (
                              <>
                                {tab.description.split(" ")[0]}
                                <span>
                                  {" "}
                                  {tab.description
                                    .split(" ")
                                    .slice(1)
                                    .join(" ")}
                                </span>
                              </>
                            )}
                          </h5>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-lg-8 col-md-12 m-auto">
                  <div id="tabs-content">
                    {tabData.map((tab) => (
                      <div
                        key={tab.id}
                        id={`tab${tab.id}`}
                        className="tab-contents"
                        style={{
                          display: activeTab === tab.id ? "block" : "none",
                        }}
                      >
                        <img
                          src={tab.imgSrc}
                          alt="no_img"
                          className="logistics_img bounce_img"
                          style={{
                            objectFit: "cover",
                            borderRadius:
                              tab.id === 1 ? "0px 50px 0px 50px" : "none",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Modaltabs;
