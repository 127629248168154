import React from "react";
// import Login from "./components/Login";
import Routers from "./components/Routers";
// import Header from "./components/Header";
// import Footer from "./components/Footer";

function App() {
  return (
    <React.Fragment>
      <Routers />
      {/* <Header />
      <Footer /> */}
    </React.Fragment>
  );
}

export default App;
