import React, { Fragment } from "react";

const Scrollbanner = () => {
  return (
    <Fragment>
      <div
        className="scroll-banner-section  banner-section"
        id="bannerSection"
        style={{ position: "relative", marginTop: "100px" }}
      >
        <div className="container">
          <div className="row">
            <div className="text-center text-white" id="heroText">
              <h1>Generative AI: Unlocking the future of fashion</h1>
              <p className="mt-4 mb-4">
                WE BUILD GENERATIVE AI GROWTH LEVERS FOR FASHION E-COMMERCE.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Scrollbanner;
