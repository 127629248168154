import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/companylogo.svg";

const Footer = () => {
  return (
    <Fragment>
      <div style={{ backgroundColor: "#032944" }}>
        <div className="banner_section_footer" style={{ position: "relative" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-6 col-sm-12">
                <img
                  src={logo}
                  alt="no_img"
                  style={{
                    height: "auto",
                    width: "160px",
                    objectFit: "contain",
                  }}
                />
                <p className="banner_section_paragraph footer_paragraph mt-4 p-0 text-white">
                  Any resemblance of the synthetic models to any real person is
                  not intentional. Click here to report any concerns regarding
                  synthetic models to our grievance officer.
                </p>
                <p className="text-white">Follow Us</p>
                <div className="nav justify-content-center d-flex">
                  <div className="social_links">
                    <div className="social-icons-btn">
                      <Link className="icons facebook" to="/">
                        <i
                          className="fa-brands fa-twitter"
                          style={{
                            height: "18px",
                            width: "16px",
                            color: " #D3D6D8",
                          }}
                        ></i>
                      </Link>
                      <Link className="icons instagram" to="/#">
                        <i
                          className="fa-brands fa-instagram"
                          style={{
                            height: "18px",
                            width: "16px",
                            color: " #D3D6D8",
                          }}
                        ></i>
                      </Link>
                      <Link className="icons linkedin" to="/#">
                        <i
                          className="fa-brands fa-linkedin"
                          style={{
                            height: "18px",
                            width: "16px",
                            color: " #D3D6D8",
                          }}
                        ></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-center gap-4 footer_links pt-3">
                  <div>
                    <Link to0="/#">Privacy Policy</Link>
                  </div>
                  <div>
                    <Link to="/#">Terms of use</Link>
                  </div>
                </div>
              </div>
              <div
                className="footer_center_logo mt-5"
                style={{ textAlign: "center" }}
              >
                <div className="copy_right_section">
                  <h2 className=" copyright_heading mt-3">
                    Copyright © NexusWare 2024. All rights reserved
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
