import React, { Fragment } from "react";

const faqs = [
  {
    id: "flush-collapseOne",
    question: "What is the tech readiness?",
    answer:
      "We have trained the algorithm for T-shirts and it is working very well (demo available). However, the training for other Article Types is pending still. The reason behind that is that each Article Type training run is several thousands of dollars, for which we are holding off for the time being until we have adequate resources, and then will take it ahead accordingly as soon as possible.",
  },
  {
    id: "flush-collapseTwo",
    question: "What will be the workflow?",
    answer:
      "The API will open up the camera on mobile, and enable the customer to take a photo of themselves within a rough outline. Once uploaded, they can swipe through the catalog and mix-and-match apparels to create the ideal look. The changing of the apparel will be adapted to the contours of the body and will have reasonable pose flexibility as well.",
  },
  {
    id: "flush-collapseThree",
    question: "What is the pricing structure?",
    answer:
      "This will be on a pay-per-thousand-API-calls mechanism. We will aim to deliver at least a 15x ROI for brands. At this moment, specific pricing is not finalized though.",
  },
  {
    id: "flush-collapseFour",
    question: "Is this live anywhere at the moment?",
    answer:
      "Not as yet. We are in the process of exploring this with a few clients that are focused primarily on T-shirts.",
  },
  {
    id: "flush-collapseFive",
    question:
      "Are there any restrictions on the type of apparel Nexusware  can process?",
    answer:
      "Translucent or netted fabrics are a strict no-go. We also will not be enabling this solution for any innerwear. Except for that, we should be all clear.",
  },
];

const Apperelfaq = () => {
  return (
    <Fragment>
      <div className="tabs_section accordion_section" id="about">
        <div className="container">
          <div className="d-flex justify-content-start align-items-center gap-3">
            <h1 className="mb-4 card_title faq_heading">FAQs</h1>
            <p className="faq_line"></p>
          </div>
          <div className="row flex-wrap-md-reverse">
            <div className="col-md-12 mt-2 response-device-padding">
              <div className="faq_info_tab">
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  {faqs.map((faq, index) => (
                    <div className="accordion-item" key={index}>
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#${faq.id}`}
                          aria-expanded="false"
                          aria-controls={faq.id}
                        >
                          {faq.question}
                        </button>
                      </h2>
                      <div
                        id={faq.id}
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">{faq.answer}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Apperelfaq;
