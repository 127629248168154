import React, { Fragment, useState } from "react";

const tabData = [
  {
    id: 1,
    title: "SPEED",
    description: "0.5s Lighting quick generation",
    imgSrc:
      "https://img.freepik.com/free-vector/branding-concept-landing-page_52683-19703.jpg?t=st=1717777454~exp=1717781054~hmac=c3741246c1bb175320db26e89b208f00ace708d8437dd15efa80516fa109fdd2&w=996",
  },
  {
    id: 2,
    title: "RETURNS",
    description: "20% Return rate reduction",
    imgSrc:
      "https://img.freepik.com/free-vector/statistics-concept-illustration_114360-172.jpg?t=st=1717778635~exp=1717782235~hmac=edf3ec2026ccb9e3f1d91e1b4fff96cace9a5fa0f3b05b2373bd14e2a9e54824&w=996",
  },
  {
    id: 3,
    title: "CONVERSION",
    description: "10% Higher Higher conversion",
    imgSrc:
      "https://img.freepik.com/free-vector/agile-method-concept-illustration_114360-9826.jpg?t=st=1717778676~exp=1717782276~hmac=93d38210ceffa6f826649ebc7e4ad4e0caae41571052ff2488ee2f800ac65cee&w=996",
  },
  {
    id: 4,
    title: "UGC",
    description: "+2% Traffic via User Generated Content",
    imgSrc:
      "https://img.freepik.com/free-vector/illustration-people-avatar-business-plan-concept_53876-37215.jpg?t=st=1717778768~exp=1717782368~hmac=bd7cf53a24bab4bb2a90529260d13c8a95f31a1baba7b611e90b7ac7f332a311&w=900",
  },
];

const Appereltab = () => {
  const [activeTab, setActiveTab] = useState(1);
  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  return (
    <Fragment>
      <section className="tabs_section">
        <div className="container">
          <div className="row g-4">
            <h6 className="international_heading_main_heading">
              Our Value Proposition
            </h6>

            <div className="tabs">
              <div className="row g-4">
                <div className="col-lg-4 col-md-12">
                  <ul id="tabs-nav">
                    {tabData.map((tab) => (
                      <li
                        key={tab.id}
                        className={activeTab === tab.id ? "active" : ""}
                        onMouseEnter={() => handleTabChange(tab.id)}
                      >
                        <a href="javascript:void(0);">
                          <h6 className="text-start international_heading_sub_heading">
                            {tab.title}
                          </h6>
                          <h5
                            className="customer_descriptions"
                            style={{
                              display: activeTab === tab.id ? "block" : "none",
                            }}
                          >
                            {tab.id === 5 ? (
                              <span>
                                &#8734;
                                <span> {tab.description.split(" ")[1]}</span>
                              </span>
                            ) : (
                              <>
                                {tab.description.split(" ")[0]}
                                <span>
                                  {" "}
                                  {tab.description
                                    .split(" ")
                                    .slice(1)
                                    .join(" ")}
                                </span>
                              </>
                            )}
                          </h5>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-lg-8 col-md-12 m-auto">
                  <div id="tabs-content">
                    {tabData.map((tab) => (
                      <div
                        key={tab.id}
                        id={`tab${tab.id}`}
                        className="tab-contents"
                        style={{
                          display: activeTab === tab.id ? "block" : "none",
                        }}
                      >
                        <img
                          src={tab.imgSrc}
                          alt="no_img"
                          className="logistics_img bounce_img"
                          style={{
                            objectFit: "cover",
                            borderRadius:
                              tab.id === 1 ? "0px 50px 0px 50px" : "none",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Appereltab;
