import React, { Fragment } from "react";
import OwlCarousel from "react-owl-carousel";
import about from "../../assets/images/abouts.jpg";

const About = () => {
  const carouselItems = [
    {
      id: 1,
      text: "We enable brands to upgrade their first catalog image as well as their digital marketing creatives. This will lead to an increase in your conversion - specifically the ‘List Page to Product Page’ and also get you a higher ROI on your Digital Ad spends.",
    },
    {
      id: 2,
      text: "We enable brands to upgrade their first catalog image as well as their digital marketing creatives. This will lead to an increase in your conversion - specifically the ‘List Page to Product Page’ and also get you a higher ROI on your Digital Ad spends.",
    },
    {
      id: 3,
      text: "We enable brands to upgrade their first catalog image as well as their digital marketing creatives. This will lead to an increase in your conversion - specifically the ‘List Page to Product Page’ and also get you a higher ROI on your Digital Ad spends.",
    },
  ];

  const options = {
    margin: 15,
    nav: false,
    dots: true,
    loop: true,
    // margin: 10,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  return (
    <Fragment>
      <div
        className="vitrual_section"
        style={{
          position: "relative",
          background: "unset",
          padding: "50px 0px 50px",
        }}
      >
        <div className="container">
          <h2 className="banner_sub_heading w-100">
            <b>Nexusware</b> is a generative AI startup focused on the fashion
            industry.
          </h2>
          <div className="row mt-4">
            <div className="col-md-12 col-lg-7 m-auto">
              <OwlCarousel
                className="owl-theme about_owl_carosuel"
                {...options}
                style={{ position: "relative" }}
              >
                {carouselItems.map((item) => (
                  <div
                    key={item.id}
                    className="item rounded"
                    style={{ borderRadius: "0px 50px 0px 50px" }}
                  >
                    <div className="row p-2 row-cols-1 row-cols-md-12">
                      <div className="col ">
                        <div
                          className="card easyshoot_card h-100 p-4"
                          style={{ background: "aliceblue" }}
                        >
                          <div className="card-body">
                            <p className="card_paragraph about_carousel_text">
                              {item.text}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
            <div className="col-md-12 col-lg-5">
              <div className="bannerimage about-banner-img">
                <img src={about} alt="no_img" className="bounce_img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default About;
