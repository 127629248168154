import React, { Fragment, useEffect, useRef } from "react";
import OwlCarousel from "react-owl-carousel";
import img1 from "../../assets/images/img_one.webp";
import img2 from "../../assets/images/img_two.avif";
import img3 from "../../assets/images/img_three.jpg";
import Virtualinfluencercards from "./Virtualinfluencercards";
import Virtualinfluencertabs from "./Virtualinfluencertabs";
import Virtualinfluencerfaq from "./Virtualinfluencerfaq";
const Virtualinfluencer = () => {
  const options = {
    margin: 16,
    nav: false,
    loop: true,
    autoplay: true,
    animateOut: "fadeOut",
    dots: false,
    touchDrag: false,
    autoplayTimeout: 1300,
    autoplaySpeed: 1300,
    mouseDrag: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  return (
    <Fragment>
      <div
        className="banner_section easyshoot_section"
        style={{ position: "relative" }}
      >
        <div className="container">
          <div className="row" style={{ position: "relative" }}>
            <div className="col-md-12 col-lg-7 col-sm-12 m-auto">
              <h1 className="banner_section_heading blue_text p-0 m-0">
                VIRTUAL INFLUENCERS
              </h1>
              {/* <h2 className="banner_sub_heading">SWITCH THE PERSON</h2> */}
              <p className="banner_section_paragraph mt-4">
                We partner with your marketing team to create your own
                photorealistic brand ambassador
              </p>
              <span className="banner_section_paragraph blue_text">
                What we enable
              </span>
              <div className="title-service">
                <ul className="p-0">
                  <li>
                    <p className="p-0 m-0">
                      <img src="/images/editorial/left-head-arrow.svg" alt="" />
                      An influencer that reflects your visual identity
                    </p>
                  </li>
                  <li>
                    <p className="p-0 m-0">
                      <img src="/images/editorial/left-head-arrow.svg" alt="" />
                      Posts from any location in the world
                    </p>
                  </li>
                  <li>
                    <p className="p-0 m-0">
                      <img src="/images/editorial/left-head-arrow.svg" alt="" />
                      Wearning any apparel from the brand's catalog
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-12 col-lg-5 col-sm-12">
              <OwlCarousel className="owl-theme" {...options}>
                {/* <div className="item">
                  <div className="blob"></div>
                </div>
                <div className="item">
                  <div className="blob2"></div>
                </div>
                <div className="item">
                  <div className="blob3"></div>
                </div> */}
                <div className="item">
                  <div className="separator">
                    <img
                      src={img1}
                      className="reponse_image"
                      loading="lazy"
                      alt="no_img"
                    />
                  </div>
                </div>
                <div className="item">
                  <div className="separator">
                    <img
                      src={img2}
                      className="reponse_image"
                      loading="lazy"
                      alt="no_img"
                    />
                  </div>
                </div>
                <div className="item">
                  <div className="separator">
                    <img
                      src={img3}
                      className="reponse_image"
                      loading="lazy"
                      alt="no_img"
                    />
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
      <Virtualinfluencercards />
      <Virtualinfluencertabs />
      <Virtualinfluencerfaq />
    </Fragment>
  );
};

export default Virtualinfluencer;
