import React, { Fragment, useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import contactus from "../../assets/images/contactus.jpg";

const Contact = () => {
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const recaptchaRef = useRef(null);

  const notify = () => {
    toast.success("Your submission has been sent!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };

  // toast("Your submission has been sent!");

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(name);
    console.log(company);
    console.log(email);
    console.log(number);
    console.log(message);
    notify();
    setName("");
    setCompany("");
    setEmail("");
    setNumber("");
    setMessage("");
    recaptchaRef.current.reset();
    setIsButtonEnabled(false);
  };

  const handleCaptchaChange = (val) => {
    setIsButtonEnabled(val !== null);
  };

  const buttonStyle = {
    backgroundColor: isButtonEnabled ? "#032944" : "#D3D3D3",
    color: isButtonEnabled ? "#FFF" : "gray",
    cursor: isButtonEnabled ? "pointer" : "not-allowed",
    border: isButtonEnabled ? "none" : "none",
  };

  return (
    <div>
      <Fragment>
        <div className="contact_us_section" style={{ position: "relative" }}>
          <div className="container">
            <div
              className="row"
              style={{ position: "relative", zIndex: "999" }}
            >
              <div className="col-md-12 col-lg-6 col-sm-12 m-auto">
                <div className="bannerimage about-banner-img">
                  <img
                    src={contactus}
                    className="bounce_img"
                    alt="Communication concept"
                  />
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-sm-12">
                <form
                  onSubmit={handleSubmit}
                  className="mb-5"
                  id="submit-form"
                  action=""
                  name="contactForm"
                >
                  <h1 className="signup_heading mb-2">
                    Hello,👋 <br /> Let’s get in touch with us
                  </h1>
                  <p className="loginpage-paragraph">
                    Let us be the part of your SUCCESS to help you reach your
                    business goals
                  </p>
                  <div className="row mb-3">
                    <div className="col-md-12 form-group">
                      <label htmlFor="fname">Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        id="fname"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-12 form-group">
                      <label htmlFor="companyname">Company *</label>
                      <input
                        type="text"
                        className="form-control"
                        id="companyname"
                        name="companyname"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-12 col-sm-12 form-group">
                      <label htmlFor="email">Email *</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-12 col-sm-12 form-group">
                      <label htmlFor="mobile_code">Phone Number *</label>
                      <br />
                      <input
                        style={{ width: "100%" }}
                        type="tel"
                        id="mobile_code"
                        className="form-control"
                        placeholder="enter your phone number.."
                        name="phone"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-12 col-sm-12 form-group">
                      <label htmlFor="message">Message *</label>
                      <textarea
                        className="form-control"
                        id="message"
                        name="message"
                        placeholder="enter your message.."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                      ></textarea>
                    </div>
                  </div>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LfU7hMqAAAAAImhq9KGlSZNoejfCq7pie9Upb4d"
                    onChange={handleCaptchaChange}
                  />
                  <div className="d-grid gap-2 mt-5 banner_buttons d-flex justify-content-center mt-3">
                    <button
                      type="submit"
                      className="login_button"
                      disabled={!isButtonEnabled}
                      style={buttonStyle}
                    >
                      Talk to experts
                    </button>
                    <ToastContainer
                      position="bottom-right"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col p-0 m-0">
              <div>
                <iframe
                  className="map_section"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248849.90089943376!2d77.46612593299314!3d12.953945614011557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1670c9b44e6d%3A0xf8dfc3e8517e4fe0!2sBengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1718815467589!5m2!1sen!2sin"
                  width="100%"
                  height="400"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </div>
  );
};

export default Contact;
