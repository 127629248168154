import React, { useState, useRef, useEffect, Fragment } from "react";
import html2canvas from "html2canvas";
import Apperelswapcard from "./Apperelswapcard";
import Appereltab from "./Appereltab";
import Apperelfaq from "./Apperelfaq";
import img1 from "../../assets/images/1_bg_swap.jpeg";
import img2 from "../../assets/images/3_bg_swap.png";

const Apperelswap = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [isZooming, setIsZooming] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [startWidth, setStartWidth] = useState(300);
  const [startHeight, setStartHeight] = useState(200);
  const [startMouseX, setStartMouseX] = useState(0);
  const [startMouseY, setStartMouseY] = useState(0);
  const [zoomFactor, setZoomFactor] = useState(1);
  const dragRef = useRef(null);
  const imgRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isDragging) {
        if (dragRef.current) {
          dragRef.current.style.left = `${e.clientX - startX}px`;
          dragRef.current.style.top = `${e.clientY - startY}px`;
        }
      }

      if (isResizing) {
        if (dragRef.current) {
          const dx = e.clientX - startMouseX;
          const dy = e.clientY - startMouseY;
          const newWidth = startWidth + dx;
          const newHeight = startHeight + dy;
          const aspectRatio = startWidth / startHeight;
          const constrainedHeight = newWidth / aspectRatio;

          dragRef.current.style.width = `${newWidth}px`;
          dragRef.current.style.height = `${constrainedHeight}px`;
          if (imgRef.current) {
            imgRef.current.style.width = "100%";
            imgRef.current.style.height = "100%";
          }
        }
      }

      if (isZooming) {
        if (dragRef.current) {
          const rect = dragRef.current.getBoundingClientRect();
          const zoomDelta = (e.clientY - rect.bottom) / rect.height;
          const newZoomFactor = zoomFactor + zoomDelta;
          const newWidth = startWidth * newZoomFactor;
          const newHeight = startHeight * newZoomFactor;
          const aspectRatio = startWidth / startHeight;
          const constrainedHeight = newWidth / aspectRatio;
          dragRef.current.style.width = `${newWidth}px`;
          dragRef.current.style.height = `${constrainedHeight}px`;
          imgRef.current.style.width = "100%";
          imgRef.current.style.height = "100%";
        }
      }
    };

    const handleDownload = async () => {
      try {
        const img1Canvas = await html2canvas(dragRef.current, {
          backgroundColor: null,
          scale: 2,
        });

        const img1Context = img1Canvas.getContext("2d");
        const img1Width = img1Canvas.width;
        const img1Height = img1Canvas.height;
        const img1Rect = dragRef.current.getBoundingClientRect();
        const containerRect =
          dragRef.current.parentElement.getBoundingClientRect();
        const img1X = img1Rect.left - containerRect.left;
        const img1Y = img1Rect.top - containerRect.top;
        const backgroundImage = new Image();
        backgroundImage.src = img2;
        backgroundImage.crossOrigin = "Anonymous";

        backgroundImage.onload = () => {
          const finalCanvas = document.createElement("canvas");
          finalCanvas.width = backgroundImage.width;
          finalCanvas.height = backgroundImage.height;
          const finalCtx = finalCanvas.getContext("2d");

          finalCtx.drawImage(
            backgroundImage,
            0,
            0,
            finalCanvas.width,
            finalCanvas.height,
          );
          finalCtx.drawImage(
            img1Canvas,
            0,
            0,
            img1Width,
            img1Height,
            img1X,
            img1Y,
            img1Width,
            img1Height,
          );
          const link = document.createElement("a");
          link.href = finalCanvas.toDataURL("image/jpeg");
          link.download = "combined-image.jpg";
          link.click();
        };
        backgroundImage.onerror = () => {
          console.error("Error loading background image");
        };
      } catch (error) {
        console.error("Error capturing and combining images:", error);
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      setIsResizing(false);
      setIsZooming(false);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [
    isDragging,
    isResizing,
    isZooming,
    startX,
    startY,
    startWidth,
    startHeight,
    startMouseX,
    startMouseY,
    zoomFactor,
  ]);

  const handleMouseDown = (e) => {
    if (e.target.classList.contains("resize-handle")) {
      setIsResizing(true);
      setStartWidth(
        parseFloat(getComputedStyle(dragRef.current).width.replace("px", "")),
      );
      setStartHeight(
        parseFloat(getComputedStyle(dragRef.current).height.replace("px", "")),
      );
      setStartMouseX(e.clientX);
      setStartMouseY(e.clientY);
      e.preventDefault();
    } else if (e.target.classList.contains("zoom-handle")) {
      setIsZooming(true);
      setStartWidth(
        parseFloat(getComputedStyle(dragRef.current).width.replace("px", "")),
      );
      setStartHeight(
        parseFloat(getComputedStyle(dragRef.current).height.replace("px", "")),
      );
      setStartMouseX(e.clientX);
      setStartMouseY(e.clientY);
      setZoomFactor(1);
      e.preventDefault();
    } else {
      setIsDragging(true);
      setStartX(e.clientX - dragRef.current.offsetLeft);
      setStartY(e.clientY - dragRef.current.offsetTop);
      e.preventDefault();
    }
  };

  const handleDownload = async () => {
    try {
      const img1Canvas = await html2canvas(dragRef.current, {
        backgroundColor: null,
        scale: 2,
      });

      const img1Context = img1Canvas.getContext("2d");
      const img1Width = img1Canvas.width;
      const img1Height = img1Canvas.height;
      const img1Rect = dragRef.current.getBoundingClientRect();
      const containerRect =
        dragRef.current.parentElement.getBoundingClientRect();
      const img1X = img1Rect.left - containerRect.left;
      const img1Y = img1Rect.top - containerRect.top;

      const backgroundImage = new Image();
      backgroundImage.src = img2;
      backgroundImage.crossOrigin = "Anonymous";

      backgroundImage.onload = () => {
        const finalCanvas = document.createElement("canvas");
        finalCanvas.width = backgroundImage.width;
        finalCanvas.height = backgroundImage.height;
        const finalCtx = finalCanvas.getContext("2d");
        finalCtx.drawImage(
          backgroundImage,
          0,
          0,
          finalCanvas.width,
          finalCanvas.height,
        );
        finalCtx.drawImage(
          img1Canvas,
          0,
          0,
          img1Width,
          img1Height,
          img1X,
          img1Y,
          img1Width,
          img1Height,
        );

        const link = document.createElement("a");
        link.href = finalCanvas.toDataURL("image/jpeg");
        link.download = "combined-image.jpg";
        link.click();
      };
      backgroundImage.onerror = () => {
        console.error("Error loading background image");
      };
    } catch (error) {
      console.error("Error capturing and combining images:", error);
    }
  };

  return (
    <Fragment>
      <div>
        <div
          className="banner_section easyshoot_section"
          style={{ position: "relative" }}
        >
          <div className="container">
            <div className="row" style={{ position: "relative" }}>
              <div className="col-md-12 col-lg-7 col-sm-12 m-auto">
                <h1 className="banner_section_heading blue_text p-0 m-0">
                  Backdrop Changes
                </h1>
                <h2 className="banner_sub_heading">(PRE-RELEASE)</h2>
                <p className="banner_section_paragraph mt-4">
                  Upload a photo of yourself and visualize yourself in any
                  apparel.
                </p>
                <span className="banner_section_paragraph blue_text">
                  Our patented algorithm:
                </span>
                <div className="title-service">
                  <ul className="p-0">
                    <li>
                      <p className="p-0 m-0">
                        <img
                          src="/images/editorial/left-head-arrow.svg"
                          alt=""
                        />
                        Incredibly fast generation time (&lt;1 second)
                      </p>
                    </li>
                    <li>
                      <p className="p-0 m-0">
                        <img
                          src="/images/editorial/left-head-arrow.svg"
                          alt=""
                        />
                        Adapts to the lighting of the source image
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="col-md-12 col-lg-5 col-sm-12 rounded"
                style={{ borderRadius: "0px 50px 0px 50px" }}
              >
                <div
                  className="resize-container"
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "70vh",
                    backgroundImage: `url(${img2})`,
                    backgroundSize: "cover",
                    backgroundPosition: "top top",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <div
                    className="resize-drag"
                    ref={dragRef}
                    onMouseDown={handleMouseDown}
                    style={{ width: "150px" }}
                  >
                    <img
                      src={img1}
                      alt="Resizable Image"
                      ref={imgRef}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        objectPosition: "center",
                      }}
                    />
                    <div className="resize-handle bottom-right"></div>
                    {/* <div className="zoom-handle bottom-left"></div> */}
                  </div>
                </div>
                {/* <div className="d-flex justify-content-end align-items-center">
                  <button
                    className="login_button mt-3"
                    onClick={handleDownload}
                  >
                    Download
                  </button>
                </div> */}

                {/* Uncomment if you want to use OwlCarousel */}
                {/* <OwlCarousel className="owl-theme" {...options}>
                  <div className="item">
                    <div className="blob"></div>
                  </div>
                  <div className="item">
                    <div className="blob2"></div>
                  </div>
                  <div className="item">
                    <div className="blob3"></div>
                  </div>
                </OwlCarousel> */}
              </div>
            </div>
          </div>
        </div>
        <div className="scrollable-container">
          <Apperelswapcard />
          <Appereltab />
          <Apperelfaq />
        </div>
      </div>
    </Fragment>
  );
};

export default Apperelswap;
