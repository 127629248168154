import React, { Fragment } from "react";

const serviceFaqs = [
  {
    id: "flush-collapseOne",
    question: "What are the guidelines for sending Nexusware a catalog image?",
    answer:
      "Most conventionally shot catalog images should be absolutely fine. All we need is the good diffused lighting that portrays your apparel with the right colour. We’ll do the rest.",
  },
  {
    id: "flush-collapseTwo",
    question: "Do you offer a free pilot?",
    answer:
      "Absolutely. We’re happy to process a few images. We’re confident you’ll love us once you’ve tried us out!",
  },
  {
    id: "flush-collapseThree",
    question: "Can Nexusware's synthetic human's face resemble a real person?",
    answer:
      "From a technological standpoint, that probability is the same probability as being born with a doppleganger. However, every face we create is passed through a third party facial recognition tool that scans the internet and checks for similar faces (www.pimeyes.com). If there is a match, we simply regenerate the image till we get a totally unique face. This way your images are totally safe to use!",
  },
  {
    id: "flush-collapseFour",
    question: "Can the pose be made slightly less right?",
    answer:
      "Unfortunately this is a limitation of this workflow. The pose will be exactly the same as that of the mannequin.",
  },
  {
    id: "flush-collapseFive",
    question:
      "What happens if Nexusware sends across an image the you don't like?",
    answer:
      "No problem. We’ll re-do it. As long as you give us feedback on why you don’t like it, we’re happy to change it out any number of times.",
  },
  {
    id: "flush-collapseSix",
    question: "What is the pricing structure?",
    answer:
      "We process images on a cost-per-image basis. The price would be dependent on the volume of images and the Turn Around Time (TAT) required. Secondly, if we are able to roll this out with you on a recurring monthly model with a minimum quantity (versus a pay-as-you-go model) we would be able to offer you a lower rate. The idea is that we can then deploy a team specifically for your brand. We recommend a pay-as-you-go model first so you are comfortable with us first.",
  },
];

const generalFaqs = [
  {
    id: "flush-collapseOnes",
    question: "Are Nexusware images live anywhere at the moment?",
    answer:
      "Yes they are. However it’s at the discretion of the brand to let people know that they are AI model images. So we can’t share links here unfortunately but do get in touch and we’ll be able to solve any apprehensions.",
  },
  {
    id: "flush-collapseTwos",
    question:
      "Are there any restrictions on the type of apparel Nexusware can process?",
    answer:
      "With translucent or netted fabrics, there could be an issue when you change the model ethnicity or skin-colour. Except for that, we should be all clear.",
  },
  {
    id: "flush-collapseThrees",
    question:
      "Can Nexusware do the catalog image shoot as well as provide us with the marketing image?",
    answer:
      "Yes we can. We’re based out of Bangalore, India so you would have to send us the apparel. We can shoot conventional catalog images and send you both - the 5/6 images you get from a conventional catalog shoot, as well as one image swapped out into a marketing image. Alternatively, we can collaborate with a local studio that you work with too to make this a seamless service for you too.",
  },
  {
    id: "flush-collapseFours",
    question: "Can you do kids apparels",
    answer:
      "Yes. We do need the apparel to be shot on a mannequin of that particular size though. The drape and fall of the fabric will be exactly maintained.",
  },
  {
    id: "flush-collapseFives",
    question: "Can you do more than just the Front & Back",
    answer:
      "At the moment, we’re restricted to Front & Back only. We are working on other angles too and should have a feature update soon.",
  },
];

const Nomodalfaq = () => {
  return (
    <Fragment>
      <div className="tabs_section accordion_section" id="about">
        <div className="container">
          <div className="d-flex justify-content-start align-items-center gap-3">
            <h1 className="mb-4 card_title faq_heading">FAQs</h1>
            <p className="faq_line"></p>
          </div>
          <div className="row flex-wrap-md-reverse">
            <div className="col-md-12 mt-2 response-device-padding">
              <div className="faq_info_tab">
                <ul
                  className="nav nav-pills mb-4"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item me-4" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-one-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-one"
                      type="button"
                      role="tab"
                      aria-controls="pills-one"
                      aria-selected="true"
                    >
                      Service FAQs
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-two-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-two"
                      type="button"
                      role="tab"
                      aria-controls="pills-two"
                      aria-selected="false"
                    >
                      General FAQs
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-one"
                    role="tabpanel"
                    aria-labelledby="pills-one-tab"
                    tabIndex="0"
                  >
                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      {serviceFaqs.map((faq, index) => (
                        <div className="accordion-item" key={index}>
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#${faq.id}`}
                              aria-expanded="false"
                              aria-controls={faq.id}
                            >
                              {faq.question}
                            </button>
                          </h2>
                          <div
                            id={faq.id}
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">{faq.answer}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-two"
                    role="tabpanel"
                    aria-labelledby="pills-two-tab"
                    tabIndex="1"
                  >
                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExamples"
                    >
                      {generalFaqs.map((faq, index) => (
                        <div className="accordion-item" key={index}>
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#${faq.id}`}
                              aria-expanded="false"
                              aria-controls={faq.id}
                            >
                              {faq.question}
                            </button>
                          </h2>
                          <div
                            id={faq.id}
                            className="accordion-collapse collapse"
                            aria-labelledby={`flush-heading${index}`}
                            data-bs-parent="#accordionFlushExamples"
                          >
                            <div className="accordion-body">{faq.answer}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Nomodalfaq;
