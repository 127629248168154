import React, { Fragment, useEffect, useRef } from "react";
import OwlCarousel from "react-owl-carousel";
import faceswap_m1 from "../../assets/images/1_model-v2.png";
import faceswap_m2 from "../../assets/images/1_original.jpg";
import faceswap_m3 from "../../assets/images/1_face_swapped.jpeg";
import backdrop_m1 from "../../assets/images/1_bg_swap.jpeg";
import backdrop_m2 from "../../assets/images/3_bg_swap.png";
import easyshoot_m1 from "../../assets/images/ps1.jpg";
import easyshoot_m2 from "../../assets/images/ps2.jpg";
import easyshoot_m3 from "../../assets/images/ps3.jpg";
import apparelswap_m1 from "../../assets/images/as1.png";
import apparelswap_m2 from "../../assets/images/as2.jpg";
import apparelswap_m3 from "../../assets/images/as3.png";
import apparelswap_m4 from "../../assets/images/as4.jpg";
import apparelswap_m5 from "../../assets/images/as5.jpg";
import apparelswap_m6 from "../../assets/images/as6.png";
import modelswap_m1 from "../../assets/images/gan1.png";
import modelswap_m2 from "../../assets/images/model_2.png";
import modelswap_m3 from "../../assets/images/gan3.png";

const imagesData = [
  {
    type: "faceswap",
    images: [faceswap_m2, faceswap_m3],
    heading: "Face Swapper",
    subHeading: "FACE SWAP IMAGES",
    description:
      "Change the face of your models. No need to work with multiple models.",
  },
  {
    type: "backdrop",
    images: [backdrop_m1, backdrop_m2],
    heading: "BACKGROUNDSWAP",
    subHeading: "BACKDROP CHANGES",
    description:
      "Select dress that suits for perfect location, and match it with the perfect background",
    listItems: [
      "Beta - Launching in October 2024",
      "Generate Picture Perfect Models within Minutes",
      "Zero post-production",
    ],
  },
  {
    type: "EASYSHOOT",
    images: [easyshoot_m1, easyshoot_m2, easyshoot_m3],
    heading: "EASYSHOOT",
    subHeading: "MANIPULATE POSTURES",
    description: "Why stuck with only one pose when you can try at all angles",
  },
  {
    type: "APPARELSWAP",
    images: [
      apparelswap_m1,
      apparelswap_m2,
      apparelswap_m3,
      apparelswap_m4,
      apparelswap_m5,
      apparelswap_m6,
    ],
    heading: "APPARELSWAP",
    subHeading: "VIRTUAL TRY-ONS",
    description: "There is no limit to fashion if you can do virtual try on",
    listItems: [
      "Beta - Launching in April 2024",
      "Generate Picture Perfect Models within Minutes",
      "Zero post-production",
    ],
  },
  {
    type: "MODELSWAP",
    images: [modelswap_m1, modelswap_m2, modelswap_m3],
    heading: "MODELSWAP",
    subHeading: "SWAP TO SYNTHETIC HUMANS",
    description:
      "Upgrade to conventionally beautiful international models seamlessly.",
  },
];

const options = {
  margin: 16,
  nav: false,
  loop: true,
  autoplay: true,
  animateOut: "fadeOut",
  dots: false,
  touchDrag: false,
  autoplayTimeout: 1300,
  autoplaySpeed: 1300,
  mouseDrag: false,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

const Models = () => {
  const animationRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("zoom-in");
        } else {
          entry.target.classList.remove("zoom-in");
        }
      });
    });

    animationRefs.current.forEach((div) => {
      if (div) observer.observe(div);
    });

    return () => {
      if (observer) {
        animationRefs.current.forEach((div) => {
          if (div) observer.unobserve(div);
        });
      }
    };
  }, []);

  return (
    <Fragment>
      {imagesData.map((section, index) => (
        <div
          className="easyshot_section"
          style={{ position: "relative" }}
          key={index}
        >
          <div className="container">
            <div
              className={`row logistic_column_reverse ${
                index % 2 === 1 ? "flex-md-row-reverse flex-column" : ""
              }`}
            >
              <div
                className="col-md-12 col-lg-5 col-sm-12 rounded"
                style={{ borderRadius: "0px 50px 0px 50px" }}
              >
                <OwlCarousel className="owl-theme" {...options}>
                  {section.images.map((image, idx) => (
                    <div className="item" key={idx}>
                      <div className="separator">
                        <img
                          src={image}
                          className="reponse_image"
                          loading="lazy"
                          alt={`Image ${idx + 1}`}
                        />
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
              <div
                className="col-md-12 col-lg-7 col-sm-12 m-auto animation_div"
                style={{ paddingLeft: "5%" }}
                ref={(el) => (animationRefs.current[index] = el)}
              >
                <h1 className="banner_section_heading blue_text p-0 m-0 red_text">
                  {section.heading}
                </h1>
                <h2 className="banner_sub_heading">{section.subHeading}</h2>
                <p className="banner_section_paragraph mt-4">
                  {section.description}
                </p>
                {section.listItems && (
                  <ul className="banner_three">
                    {section.listItems.map((item, listIdx) => (
                      <li key={listIdx}>
                        <p className="p-0 m-0">{item}</p>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export default Models;
