import React, { Fragment, useEffect, useRef } from "react";
import Faq from "./Faq";
import Servicescards from "./Servicescards";
import Tabsection from "./Tabsection";

const Faceswap = () => {
  const slidersRef = useRef([]);
  const currentIndexRef = useRef(0);
  const url = "../../assets/images/1_original.jpg";
  useEffect(() => {
    const sliders = slidersRef.current;
    const prevBtn = document.getElementById("prevBtn");
    const nextBtn = document.getElementById("nextBtn");

    showSlider(currentIndexRef.current);

    prevBtn.addEventListener("click", () => {
      currentIndexRef.current =
        (currentIndexRef.current - 1 + sliders.length) % sliders.length;
      showSlider(currentIndexRef.current);
    });

    nextBtn.addEventListener("click", () => {
      currentIndexRef.current = (currentIndexRef.current + 1) % sliders.length;
      showSlider(currentIndexRef.current);
    });

    return () => {
      prevBtn.removeEventListener("click", () => {});
      nextBtn.removeEventListener("click", () => {});
    };
  }, []);

  const showSlider = (index) => {
    slidersRef.current.forEach((slider, i) => {
      if (i === index) {
        slider.style.display = "block";
        initializeSlider(slider);
      } else {
        slider.style.display = "none";
      }
    });
  };

  const initializeSlider = (slider) => {
    const compSliderWidth = slider.offsetWidth + "px";
    slider.querySelector(".resize img").style.width = compSliderWidth;
    drags(
      slider.querySelector(".divider"),
      slider.querySelector(".resize"),
      slider,
    );

    window.addEventListener("resize", () => {
      const compSliderWidth = slider.offsetWidth + "px";
      slider.querySelector(".resize img").style.width = compSliderWidth;
    });
  };

  const drags = (dragElement, resizeElement, container) => {
    let touched = false;

    window.addEventListener("touchstart", () => {
      touched = true;
    });
    window.addEventListener("touchend", () => {
      touched = false;
    });

    dragElement.addEventListener("mousedown", startDragging);
    dragElement.addEventListener("touchstart", startDragging);

    function startDragging(e) {
      dragElement.classList.add("draggable");
      resizeElement.classList.add("resizable");
      const startX = e.pageX ? e.pageX : e.touches[0].pageX;
      const dragWidth = dragElement.offsetWidth;
      const posX =
        dragElement.getBoundingClientRect().left + dragWidth - startX;
      const containerOffset = container.getBoundingClientRect().left;
      const containerWidth = container.offsetWidth;
      const minLeft = containerOffset + 50;
      const maxLeft = containerOffset + containerWidth - dragWidth - 50;

      container.addEventListener("mousemove", drag);
      container.addEventListener("touchmove", drag);

      container.addEventListener("mouseup", stopDragging);
      container.addEventListener("touchend", stopDragging);
      container.addEventListener("touchcancel", stopDragging);

      function drag(e) {
        if (!touched) {
          e.preventDefault();
        }
        const moveX = e.pageX ? e.pageX : e.touches[0].pageX;
        let leftValue = moveX + posX - dragWidth;

        if (leftValue < minLeft) {
          leftValue = minLeft;
        } else if (leftValue > maxLeft) {
          leftValue = maxLeft;
        }

        const widthValue =
          ((leftValue + dragWidth / 2 - containerOffset) * 100) /
            containerWidth +
          "%";

        dragElement.style.left = widthValue;
        resizeElement.style.width = widthValue;
      }

      function stopDragging() {
        dragElement.classList.remove("draggable");
        resizeElement.classList.remove("resizable");
        container.removeEventListener("mousemove", drag);
        container.removeEventListener("touchmove", drag);
      }
    }
  };

  return (
    <Fragment>
      <div
        class="banner_section easyshoot_section"
        style={{ position: "relative" }}
      >
        <div className="container">
          <div className="row" style={{ position: "relative" }}>
            <div className="col-md-12 col-lg-7 col-sm-12 m-auto">
              <h1 className="banner_section_heading blue_text p-0 m-0">
                FaceSwap
              </h1>
              <h2 className="banner_sub_heading">EDITORIAL IMAGES</h2>
              <p class="banner_section_paragraph mt-4">
                Convert plain vanilla studio images into high quality immersive
                editorial content.
              </p>
              <span className="banner_section_paragraph blue_text">
                We do 3 things:
              </span>
              <div className="title-service">
                <ul className="p-0">
                  <li>
                    <p className="p-0 m-0">
                      <img src="/images/editorial/left-head-arrow.svg" alt="" />
                      Switch the model to a synthetic human
                    </p>
                  </li>
                  <li>
                    <p className="p-0 m-0">
                      <img src="/images/editorial/left-head-arrow.svg" alt="" />
                      Swap out the background to suit the apparel
                    </p>
                  </li>
                  <li>
                    <p className="p-0 m-0">
                      <img src="/images/editorial/left-head-arrow.svg" alt="" />
                      We adapt the lighting to make it look natural
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-12 col-lg-5 col-sm-12">
              <div className="containers">
                <div className="inner">
                  <div className="comparison-slider-wrapper">
                    <div
                      className="comparison-slider"
                      ref={(el) => slidersRef.current.push(el)}
                    >
                      <div className="overlay">
                        <strong>After</strong> swap image
                      </div>
                      <img
                        style={{ height: "60vh" }}
                        src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/photoshop-face-after.jpg"
                        alt="marioPhoto 2"
                      />
                      <div className="resize">
                        <div className="overlay">
                          <strong>Before</strong> swap image
                        </div>
                        <img
                          src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/photoshop-face-before.jpg"
                          alt="marioPhoto 1"
                          style={{ height: "60vh" }}
                        />
                      </div>
                      <div className="divider"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Servicescards />
      <Tabsection />
      <Faq />
    </Fragment>
  );
};

export default Faceswap;
