import React, { useState, useEffect, Fragment } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

const Dashboard = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Fragment>
      <div style={{ position: "relative" }}>
        {loading && (
          <div id="preloader-background">
            <div id="loader">
              <div className="d-flex align-items-center justify-content-center">
                <div className="i">
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Header />
        <Outlet />
        <ScrollToTop />
        <Footer />
      </div>
    </Fragment>
  );
};

export default Dashboard;
