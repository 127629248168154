import React, { Fragment, useState } from "react";

const tabData = [
  {
    id: 1,
    title: "AVAILABILITY",
    description: "365 Days availability",
    imgSrc:
      "https://img.freepik.com/free-vector/scrum-method-concept-illustration_114360-9828.jpg?t=st=1717782314~exp=1717785914~hmac=8e7b732836cc783d4f9416153c0c247ef9b15cd1f2ae77b193492f3c8128a9a2&w=996",
  },
  {
    id: 2,
    title: "COSTS",
    description: "50% Cheaper content production",
    imgSrc:
      "https://img.freepik.com/free-vector/startup-managers-presenting-analyzing-sales-growth-chart-group-workers-with-heap-cash-rocket-bar-diagrams-with-arrow-heap-money_74855-14166.jpg?t=st=1717776048~exp=1717779648~hmac=3280c4b8fcee4124ecb15031b09b69aff8bcd48deb143d7b2e81d8d6211f2727&w=996",
  },
  {
    id: 3,
    title: "ROI ON ADS",
    description: "35% Higher CTRs than real influencers",
    imgSrc:
      "https://img.freepik.com/free-vector/gradient-cost-living-illustration_52683-139110.jpg?t=st=1717782545~exp=1717786145~hmac=d9d51247cdff2636c42e4974a719442d42deaa8878becbc3491b1c891d0c54b3&w=996",
  },
  {
    id: 4,
    title: "CONTROL",
    description: "Zero Risk of negative publicity",
    imgSrc:
      "https://img.freepik.com/free-vector/process-concept-illustration_114360-4229.jpg?t=st=1717782489~exp=1717786089~hmac=82b39ed552f2c9cd42c0fae6f0f6aa2f138c3a7447c31febc6958ec132eb548b&w=996",
  },
  {
    id: 5,
    title: "MOOD BOARDS",
    description: "∞ Customizations to your requirment",
    imgSrc:
      "https://img.freepik.com/free-vector/people-working-office-flat-design_52683-23712.jpg?t=st=1717777801~exp=1717781401~hmac=88b92d638e421f58fa0627b3648c35c042842c4df895e79932a5080df6495db1&w=996",
  },
];

const Virtualinfluencertabs = () => {
  const [activeTab, setActiveTab] = useState(1);
  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  return (
    <Fragment>
      <section className="tabs_section">
        <div className="container">
          <div className="row g-4">
            <h6 className="international_heading_main_heading">
              Our Value Proposition
            </h6>

            <div className="tabs">
              <div className="row g-4">
                <div className="col-lg-4 col-md-12">
                  <ul id="tabs-nav">
                    {tabData.map((tab) => (
                      <li
                        key={tab.id}
                        className={activeTab === tab.id ? "active" : ""}
                        onMouseEnter={() => handleTabChange(tab.id)}
                      >
                        <a href="javascript:void(0);">
                          <h6 className="text-start international_heading_sub_heading">
                            {tab.title}
                          </h6>
                          <h5
                            className="customer_descriptions"
                            style={{
                              display: activeTab === tab.id ? "block" : "none",
                            }}
                          >
                            {tab.id === 5 ? (
                              <span>
                                &#8734;
                                <span> {tab.description.split(" ")[1]}</span>
                              </span>
                            ) : (
                              <>
                                {tab.description.split(" ")[0]}
                                <span>
                                  {" "}
                                  {tab.description
                                    .split(" ")
                                    .slice(1)
                                    .join(" ")}
                                </span>
                              </>
                            )}
                          </h5>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-lg-8 col-md-12 m-auto">
                  <div id="tabs-content">
                    {tabData.map((tab) => (
                      <div
                        key={tab.id}
                        id={`tab${tab.id}`}
                        className="tab-contents"
                        style={{
                          display: activeTab === tab.id ? "block" : "none",
                        }}
                      >
                        <img
                          src={tab.imgSrc}
                          alt="no_img"
                          className="logistics_img bounce_img"
                          style={{
                            objectFit: "cover",
                            borderRadius:
                              tab.id === 1 ? "0px 50px 0px 50px" : "none",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Virtualinfluencertabs;
