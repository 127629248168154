import React, { Fragment, useEffect, useRef } from "react";
import OwlCarousel from "react-owl-carousel";
import Nomodalcards from "./Nomodalcards";
import Nomodaltabs from "./Nomodaltabs";
import Nomodalfaq from "./Nomodalfaq";

const Nomodal = () => {
  const options = {
    margin: 16,
    nav: false,
    loop: true,
    autoplay: true,
    animateOut: "fadeOut",
    dots: false,
    touchDrag: false,
    autoplayTimeout: 1300,
    autoplaySpeed: 1300,
    mouseDrag: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  return (
    <Fragment>
      <div
        className="banner_section easyshoot_section"
        style={{ position: "relative" }}
      >
        <div className="container">
          <div className="row" style={{ position: "relative" }}>
            <div className="col-md-12 col-lg-7 col-sm-12 m-auto">
              <h1 className="banner_section_heading blue_text p-0 m-0">
                NO-MODEL
              </h1>
              <h2 className="banner_sub_heading">MANNEQUIN TO MODEL</h2>
              <p className="banner_section_paragraph mt-4">
                Shoot on a mannequin. We substitute the mannequin with a
                photorealistic synthetic human.
              </p>
              <span className="banner_section_paragraph blue_text">
                We do 3 things:
              </span>
              <div className="title-service">
                <ul className="p-0">
                  <li>
                    <p className="p-0 m-0">
                      <img src="/images/editorial/left-head-arrow.svg" alt="" />
                      Switch the mannequin to a human
                    </p>
                  </li>
                  <li>
                    <p className="p-0 m-0">
                      <img src="/images/editorial/left-head-arrow.svg" alt="" />
                      Swap out the background to white/grey
                    </p>
                  </li>
                  <li>
                    <p className="p-0 m-0">
                      <img src="/images/editorial/left-head-arrow.svg" alt="" />
                      We adapt the lighting to make it look natural
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-12 col-lg-5 col-sm-12">
              <OwlCarousel className="owl-theme" {...options}>
                <div className="item">
                  <main className="bounce_img">
                    <div className="carousel">
                      <figure></figure>
                    </div>
                  </main>
                </div>
                <div className="item">
                  <main className="bounce_img">
                    <div className="carousel">
                      <figure className="figure2"></figure>
                    </div>
                  </main>
                </div>
                <div className="item">
                  <main className="bounce_img">
                    <div className="carousel">
                      <figure className="figure3"></figure>
                    </div>
                  </main>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
      <Nomodalcards />
      <Nomodaltabs />
      <Nomodalfaq />
    </Fragment>
  );
};

export default Nomodal;
