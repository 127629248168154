import React, { Fragment, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavLink, useLocation } from "react-router-dom";
import menuIcon from "../assets/images/menu.svg";
import Typewriter from "./Typewriter";
import Custombanner from "./homepage/Custombanner";
import logo from "../../src/assets/images/companylogo.svg";

const Header = () => {
  const location = useLocation();
  const [activeDropdown, setActiveDropdown] = useState(false);

  useEffect(() => {
    const nav = document.querySelector("nav");

    const handleScroll = () => {
      if (nav) {
        nav.classList.toggle("scrolled", window.scrollY > 5);
      }
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const isDropdownActive = [
      "/faceswap",
      "/apparel-swap",
      "/modal-swap",
      "/nomodel",
      "/virtual-influencer",
    ].includes(location.pathname);

    setActiveDropdown(isDropdownActive);
  }, [location.pathname]);

  const homeSectionClass = location.pathname === "/" ? "home-section" : "";

  const bannerConfig = {
    "/faceswap": {
      heading: "Face Swap",
      subheading: "Home",
      description: "Face Swap",
    },
    "/apparel-swap": {
      heading: "Backdrop Changes",
      subheading: "Home",
      description: "Backdrop Changes",
    },
    "/modal-swap": {
      heading: "Posture Swap",
      subheading: "Home",
      description: "Posture Swap",
    },
    "/nomodel": {
      heading: "NoModel",
      subheading: "Home",
      description: "NoModel",
    },
    "/virtual-influencer": {
      heading: "Virtual Influencers",
      subheading: "Home",
      description: "Virtual Influencers",
    },
    "/contact": {
      heading: "Contact Us",
      subheading: "Home",
      description: "Contact Us",
    },
    "/about": {
      heading: "About Us",
      subheading: "Home",
      description: "About Us",
    },
  };

  useEffect(() => {
    // Scroll to top when location.pathname changes
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Fragment>
      <div>
        <div className={homeSectionClass} style={{ position: "relative" }}>
          <nav className="navbar navbar-expand-md pt-2 bg-whites navbar-light fixed-top nav-active">
            <div className="container navbar-page">
              <NavLink to="/" className="navbar-brand">
                {/* <h3 className="darksoul-loader-h">Nexusware</h3> */}
                <img
                  src={logo}
                  alt="no_img"
                  style={{
                    height: "auto",
                    width: "160px",
                    objectFit: "contain",
                  }}
                />
              </NavLink>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon">
                  <img src={menuIcon} className="w-100" alt="menu icon" />
                </span>
              </button>
              <div
                className="collapse navbar-collapse header_menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      exact
                      to="/"
                      activeClassName="active"
                    >
                      Home
                    </NavLink>
                  </li>
                  <li
                    className={`nav-item dropdown ${
                      activeDropdown ? "" : "active"
                    }`}
                  >
                    <span
                      className={`nav-link dropdown-toggle ${
                        activeDropdown ? "" : "active"
                      }`}
                      to="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Solutions
                    </span>
                    <ul className="dropdown-menu dropdown_menu services_dropdown">
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/faceswap"
                          activeClassName="active"
                        >
                          Face Swap
                          <span>-Editorial Images</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/apparel-swap"
                          activeClassName="active"
                        >
                          BACKDROP CHANGES <span>- Virtual Try-ons</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/modal-swap"
                          activeClassName="active"
                        >
                          Posture <span>- Switch the Person</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/nomodel"
                          activeClassName="active"
                        >
                          NoModel <span>- Apparel Swap</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/virtual-influencer"
                          activeClassName="active"
                        >
                          Virtual Influencers
                          <span>- Your AI Influencer Partner</span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      to="/about"
                      activeClassName="active"
                    >
                      About
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      to="/contact"
                      activeClassName="active"
                    >
                      Contact
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          {location.pathname === "/" ? (
            <div
              className="banner_section banner_left_content banner-section"
              id="bannerSection"
              style={{ position: "relative" }}
            >
              <div className="container">
                <div className="row">
                  <div className="text-center text-white" id="heroText">
                    <h1>Generative AI: Unlocking the future of fashion</h1>
                    <h2
                      className="typewrite text-center"
                      data-period="2000"
                      data-type='["Ideate. Design. Fashion."]'
                    >
                      <Typewriter textArray={["Ideate. Design. Fashion."]} />
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Custombanner {...bannerConfig[location.pathname]} />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Header;
