import React, { useState, useEffect } from "react";
import topIcon from "../../src/assets/images/top.svg";
import downIcon from "../../src/assets/images/down.svg";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const checkIfAtBottom = () => {
    if (
      window.innerHeight + window.pageYOffset >=
      document.body.offsetHeight - 200
    ) {
      setIsAtBottom(true);
    } else {
      setIsAtBottom(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      toggleVisibility();
      checkIfAtBottom();
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="scroll-to-top">
      {isVisible && (
        <button
          onClick={isAtBottom ? scrollToTop : scrollToBottom}
          className="scroll-button"
        >
          <img src={isAtBottom ? topIcon : downIcon} alt="Scroll icon" />
        </button>
      )}
    </div>
  );
};

export default ScrollToTop;
