import React, { useEffect, useRef } from "react";

const Typewriter = ({ textArray, period = 2000 }) => {
  const el = useRef(null);
  const loopNum = useRef(0);
  const isDeleting = useRef(false);
  const txt = useRef("");

  useEffect(() => {
    const tick = () => {
      const i = loopNum.current % textArray.length;
      const fullTxt = textArray[i];

      if (isDeleting.current) {
        txt.current = fullTxt.substring(0, txt.current.length - 1);
      } else {
        txt.current = fullTxt.substring(0, txt.current.length + 1);
      }

      if (el.current) {
        el.current.innerHTML = `<span class="wrap">${txt.current}</span>`;
      }

      // Increase the delay for a slower typing effect
      let delta = 400 - Math.random() * 100;

      if (isDeleting.current) {
        delta /= 2;
      }

      if (!isDeleting.current && txt.current === fullTxt) {
        delta = period;
        isDeleting.current = true;
      } else if (isDeleting.current && txt.current === "") {
        isDeleting.current = false;
        loopNum.current++;
        delta = 1500;
      }

      setTimeout(tick, delta);
    };

    tick();
  }, [textArray, period]);

  useEffect(() => {
    const css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".typewrite > .wrap { border-right: 0.05em solid #fff}";
    document.body.appendChild(css);

    return () => {
      document.body.removeChild(css);
    };
  }, []);

  return <span ref={el} className="typewrite" />;
};

export default Typewriter;
